@mixin override {
  * {
    &:focus {
      outline: none;
    }
  }

  *::-webkit-scrollbar-track {
    background: #2f4c4f;
  }

  .lc-map {
    @include desktop {
      margin-left: 350px;
    }
    .lc-mapContainer {
      background: white !important;
    }
  }

  .lc-board {
    width: 350px !important;
    font-size: 1em !important;
    margin: 0 !important;
    background: $primary;
    border-radius: 0;
    bottom: 0;

    @include desktop {
      max-height: unset;
    }

    @include mobile {
      width: unset !important;
      padding-bottom: 30px !important;
    }

    .lc-content {
      margin: 0 !important;
      border-radius: 0;
      box-shadow: initial !important;
      background: transparent !important;
      color: white !important;
    }
  }

  .lc-board-head {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 40px 0 !important;
    background-color: #2f4c4f !important;

    img {
      width: 160px !important;
      height: inherit !important;
    }

    @include mobile {
      padding: 20px 0 !important;

      img {
        width: 75px !important;
      }
    }
  }

  .lc-elevation {
    background: transparent !important;
    margin: 0;
  }

  .lc-board-header {
    background: transparent !important;
    padding: 0 15px !important;
    border-radius: 0 !important;

    .lc-back {
      background: transparent !important;
      border: initial !important;
    }

    .lc-board-title {
      background: transparent !important;
      color: white !important;
      text-transform: uppercase !important;
      font-weight: initial !important;
      padding: 0 !important;
      white-space: initial !important;
    }
  }

  .lc-menu-item {
    background: transparent !important;
    border-color: $secondary !important;
    padding: 15px 0 !important;
    margin: 0 20px;

    &:last-child {
      border: initial !important;
    }

    &:hover {
      .lc-menu-title {
        color: $secondary !important;
      }
    }

    .lc-projects-link-selected {
      color: $secondary !important;
    }
  }

  .lc-menu-title {
    color: white !important;
    text-transform: uppercase !important;
    font-weight: initial !important;
    transition: all ease-in-out 250ms;
  }

  .lc-uimode-projects {
    border-color: $secondary !important;
    margin: 0 20px;
    padding: 15px 10px !important;

    .lc-uiline {
      font-size: initial !important;
    }
  }

  .lc-projects-bottom-text {
    font-weight: normal;
    margin: 30px 20px 0 20px;
  }
}
